import React, { VFC } from 'react'
import { GenericComponentProps, ResultTeaserCompetitionProps } from '@sport1/types/web'
import { WidgetTrackingIdentifier } from '@sport1/bets-service-client'
import ResultTeaserCompetitionCard from '@/components/ResultTeaserGeneric/CompetitionCard'
import HorizontalSlider from '@/components/HorizontalSlider'
import { UseResultTeaserTrackingReturnProps } from '@/components/ResultTeaserGeneric/utils/useResultTeaserTracking'
import { useMatchOverlay } from '@/utils/overlay/MatchOverlayProvider'

type MultiCompetitionsProps = Omit<UseResultTeaserTrackingReturnProps, 'trackMatchCardImpression'> &
    Pick<GenericComponentProps, 'fragmentMeta'> & {
        trackingCreation: WidgetTrackingIdentifier
        testID?: string
        competitionCards: ResultTeaserCompetitionProps[]
        isRightColumn?: boolean
    }

const keyExtractor = (item: ResultTeaserCompetitionProps) =>
    `result-teaser-generic-${item.matches.map(m => m.id).join('-')}`

const MultiCompetitions: VFC<MultiCompetitionsProps> = ({
    trackBetImpressionOnView,
    trackMatchCardClick,
    onViewableItemsChanged,
    competitionCards,
    trackingCreation,
    fragmentMeta,
    testID,
    isRightColumn,
}) => {
    const { closeOverlay } = useMatchOverlay()
    const ONE_CARD_WIDTH = isRightColumn ? 300 : 352
    return (
        <HorizontalSlider
            testID={`${testID}-slider`}
            horizontalScrollbarEnabled
            horizontalScrollbarInsets={{
                marginTop: 'spacing-9',
                marginLeft: 'spacing-none',
                marginRight: 'spacing-none',
            }}
            scrollOffset={ONE_CARD_WIDTH}
            itemWidth={ONE_CARD_WIDTH}
            items={competitionCards}
            keyExtractor={keyExtractor}
            renderItem={({ item, index }) => (
                <ResultTeaserCompetitionCard
                    testID={`competition-card-${index}`}
                    key={`competition-card-${index}`}
                    expandButton
                    forceMobileView
                    trackBetImpressionOnView={trackBetImpressionOnView}
                    trackMatchCardClick={trackMatchCardClick}
                    width={[ONE_CARD_WIDTH * 0.92, ONE_CARD_WIDTH * 0.92, ONE_CARD_WIDTH]}
                    trackingCreation={trackingCreation}
                    fragmentMeta={fragmentMeta}
                    positionInSlider={index}
                    variant="slider"
                    {...item}
                />
            )}
            spaceX="none"
            onViewableItemsChanged={onViewableItemsChanged}
            onScroll={closeOverlay}
            backgroundColor="TRANSPARENT"
        />
    )
}

export default MultiCompetitions
