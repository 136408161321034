import React, { FC, ReactElement, useCallback, useRef } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { DimensionProps } from '@sport1/news-styleguide/Types'
import Tab from '@sport1/news-styleguide/Tab'
import { TodayLiveCategoryModel } from '@sport1/types/web'
import sportIdentifiersConfig from '@/utils/sportIdentifiers/SportIdentifiersConfig'

export interface CategoryPickerProps extends DimensionProps {
    testID?: string
    items: TodayLiveCategoryModel[]
    selectedCategory?: TodayLiveCategoryModel
    onSelect: (item: TodayLiveCategoryModel) => void
}

const keyExtractor = ({ name }: TodayLiveCategoryModel) => `category-chip-${name}`

const CategoryPicker: FC<CategoryPickerProps> = ({
    width,
    testID,
    items,
    selectedCategory,
    onSelect,
}) => {
    const scrollRef = useRef<HTMLDivElement>(null)

    const handleSelect = useCallback(
        (item: TodayLiveCategoryModel) => {
            if (onSelect) {
                onSelect(item)
            }
            const index = items.findIndex(i => i.id === item.id)
            if (scrollRef.current && index !== -1) {
                const offset = index * (scrollRef.current.scrollWidth / items.length)
                scrollRef.current.scrollTo({
                    left: offset - 75,
                    behavior: 'smooth',
                })
            }
        },
        [onSelect, items]
    )

    const renderItem = useCallback(
        (item: TodayLiveCategoryModel): ReactElement => (
            <Tab
                key={keyExtractor(item)}
                testID={`category-${item.id}`}
                title={item.name}
                onPress={() => handleSelect(item)}
                isActive={
                    item.id === (selectedCategory?.id || sportIdentifiersConfig.soccer.id) ||
                    item.name === selectedCategory?.name
                }
            />
        ),
        [handleSelect, selectedCategory?.id, selectedCategory?.name]
    )

    return (
        <NonFlexingContainer testID={testID} width={width} overflow="auto">
            <NonFlexingContainer testID={`${testID}-horizontal-scroll`} backgroundColor="pearl">
                <div
                    ref={scrollRef}
                    style={{ display: 'flex', scrollbarWidth: 'none', overflow: 'auto' }}
                >
                    {items.map(renderItem)}
                </div>
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default CategoryPicker
