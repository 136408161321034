import React, { useMemo, VFC } from 'react'
import Touchable from '@sport1/news-styleguide/Touchable'
import { MatchClientModel } from '@sport1/types/sportData'
import { LabelProps, TagProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import TagImage from '@sport1/news-styleguide/Image'
import colorPattern from '@sport1/news-styleguide/colorPattern'
import { createCalendarHref, createGameplanHref } from '@/utils/navigation/Navigator'
import { ResultTeaserCompetitionCardProps } from '@/components/ResultTeaserGeneric/CompetitionCard'
import getHeadline from '@/components/ResultTeaserGeneric/CompetitionCard/Headline/utils/getHeadline'
import isEventBasedSportEvent from '@/utils/match/isEventBasedSportEvent'
import getSportIdentifier from '@/utils/match/getSportIdentifier'
import { sport1Regular } from '@/helpers/fonts'

type CompetitionCardHeadlineProps = Pick<ResultTeaserCompetitionCardProps, 'testID'> & {
    tag?: TagProps | LabelProps
    match?: MatchClientModel
}

const CompetitionCardHeadline: VFC<CompetitionCardHeadlineProps> = ({ tag, match, testID }) => {
    const sportAndCompName = useMemo(() => getHeadline(match), [match])
    const headline = sportAndCompName.replace(',', ' •')
    const headlineHref = useMemo(() => {
        if (match?.sport?.id === 'hs:250') return undefined
        if (match?.sport && isEventBasedSportEvent(getSportIdentifier(match))) {
            return createCalendarHref({ tag, sportId: match.sport.id })
        }
        return createGameplanHref({ tag, competitionId: match?.competition?.id })
    }, [match, tag])

    return (
        <Touchable href={headlineHref} testID={`${testID}-touchable`} backgroundColor="pearl">
            <NonFlexingContainer
                horizontal
                centerVertical
                paddingY="spacing-3"
                paddingX="spacing-6"
                marginRight="spacing-6"
                overflow="hidden"
            >
                <NonFlexingContainer
                    minWidth={24}
                    minHeight={24}
                    backgroundColor={colorPattern.ghost}
                    center
                >
                    <TagImage
                        width={20}
                        height={20}
                        imageUrl={
                            match?.competition?.imageUrl || match?.sport?.sportGroup?.imageUrl
                        }
                    />
                </NonFlexingContainer>

                <Font
                    numberOfLines={2}
                    marginLeft="spacing-3"
                    fontVariant="Body-S"
                    fontFamilyVariant="Sport1-Regular"
                    nextFontFamilyVariant={sport1Regular.style.fontFamily}
                    testID={`${testID}-touchable-headline`}
                >
                    {match?.sport?.id === 'hs:250' ? headline.replace('Allgemein', '') : headline}
                </Font>
            </NonFlexingContainer>
        </Touchable>
    )
}

export default CompetitionCardHeadline
