import React, { FC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import { sport1Regular } from '@/helpers/fonts'

type Props = {
    text: string
    testID?: string
    isRightColumn?: boolean
}

const Fallback: FC<Props> = ({ text, testID, isRightColumn }) => (
    <NonFlexingContainer
        testID={`${testID}-${text}`}
        paddingY="spacing-7"
        paddingX={isRightColumn ? 'spacing-none' : ['spacing-7', 'spacing-10', 'spacing-10']}
        width="100%"
        backgroundColor="pearl"
    >
        <Font
            fontVariant="Body-L"
            fontFamilyVariant="Sport1-Regular"
            testID={`${testID}-text`}
            nextFontFamilyVariant={sport1Regular.style.fontFamily}
        >
            {text}
        </Font>
    </NonFlexingContainer>
)

export default Fallback
