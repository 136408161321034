import React, { FC, memo, useCallback } from 'react'
import {
    GenericComponentProps,
    GenericResultTeaserModel,
    TodayLiveCategoryModel,
} from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { SportGroupClientModel } from '@sport1/types/sportData'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import CategoryPicker from './Picker'
import ResultTeaserCompetitionList from '@/components/ResultTeaserGeneric/CompetitionList'
import { useTracking } from '@/utils/tracking/TrackingProvider'

export type ResultTeaserGenericProps = Pick<
    GenericComponentProps & GenericResultTeaserModel,
    'additionalData' | 'contentUrl' | 'meta' | 'fragmentMeta'
> & {
    testID?: string
    componentIndex?: number
    isRightColumn?: boolean
}

const ResultTeaserGeneric: FC<ResultTeaserGenericProps> = ({
    contentUrl,
    additionalData,
    meta,
    fragmentMeta,
    componentIndex,
    testID,
    isRightColumn,
}) => {
    const { trackInteraction, trackIvwInfo, trackIvwView } = useTracking()
    const [selectedCategory, setSelectedCategory] = React.useState<
        SportGroupClientModel | undefined
    >(additionalData.sportGroups[0])
    const hasAtLeastOneTab = additionalData.sportGroups?.length > 1

    const getCategoryPosition = useCallback(
        (sportGroup: TodayLiveCategoryModel) =>
            additionalData.sportGroups.findIndex(e => e.id === sportGroup.id) + 1,
        [additionalData.sportGroups]
    )

    const onCategorySelect = React.useCallback(
        (value: SportGroupClientModel) => {
            trackIvwInfo({ ivw_context: 'result-teaser' })
            trackIvwView(undefined, false)
            trackInteraction({
                interaction_category: 'result-teaser',
                interaction_action: 'click',
                interaction_label: `${value.name}_${getCategoryPosition(value)}`,
            })
            setSelectedCategory(value)
        },
        [getCategoryPosition, trackInteraction, trackIvwInfo, trackIvwView]
    )

    if (!additionalData?.sportGroups?.length) {
        return null
    }

    return (
        <NonFlexingContainer testID={testID} paddingBottom="spacing-11">
            <NonFlexingContainer
                paddingBottom={hasAtLeastOneTab ? 'spacing-none' : 'spacing-6'}
                testID={`${testID}-headline-container`}
                paddingX={isRightColumn ? 'spacing-none' : ['spacing-6', 'spacing-9', 'spacing-9']}
            >
                <CardHeadline
                    text="top events"
                    href="/live"
                    cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineS']}
                    rightIconName="scroll-right"
                    testID={`${testID}-touchable`}
                />
            </NonFlexingContainer>

            {hasAtLeastOneTab && (
                <NonFlexingContainer
                    paddingTop="spacing-6"
                    paddingX={
                        isRightColumn
                            ? 'spacing-none'
                            : ['spacing-6', 'spacing-6', 'spacing-9', 'spacing-9']
                    }
                >
                    <CategoryPicker
                        testID={`${testID}-picker`}
                        items={additionalData.sportGroups}
                        selectedCategory={selectedCategory}
                        onSelect={onCategorySelect}
                    />
                </NonFlexingContainer>
            )}

            {selectedCategory?.sportIdentifier ? (
                <ResultTeaserCompetitionList
                    isRightColumn={isRightColumn}
                    testID={`${testID}-competition-list`}
                    contentUrl={contentUrl.replace(
                        '{sportIdentifier}',
                        selectedCategory.sportIdentifier
                    )}
                    componentTrackingData={meta?.tracking}
                    fragmentMeta={fragmentMeta}
                    componentIndex={componentIndex}
                    category={selectedCategory}
                    categoryPosition={getCategoryPosition(selectedCategory)}
                />
            ) : null}
        </NonFlexingContainer>
    )
}

export default memo(ResultTeaserGeneric)
