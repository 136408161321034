import { MatchClientModel } from '@sport1/types/sportData'
import { PostSetRowProps } from '@sport1/news-styleguide/Types'
import { getSportEventTeamProps, MatchUsageSource } from '@/components/SportEvent/utils'
import getSportEventWinnerProp from '@/utils/match/getSportEventWinnerProp'
import getMatchInfoProps from '@/utils/match/getMatchInfoProps'
import getSportIdentifier from '@/utils/match/getSportIdentifier'
import getSetResultProps from '@/components/SportEvent/Set/util/getSetResultProps'

const getPostSetEventProps = (
    event: MatchClientModel,
    source?: MatchUsageSource
): Omit<PostSetRowProps, 'onPressMatch'> | undefined => {
    const baseProps = getSportEventTeamProps(event)
    const winner = getSportEventWinnerProp(event)
    const setResultProps = getSetResultProps({ match: event, source })
    if (baseProps && setResultProps) {
        return {
            type: 'POST',
            ...baseProps,
            ...setResultProps,
            additionalField: getMatchInfoProps({
                resultType: event.matchInfo?.resultType,
                teamName:
                    winner === 'HOME'
                        ? baseProps.awayCompetitor.name
                        : baseProps.homeCompetitor.name,
                sportIdentifier: getSportIdentifier(event),
                source,
            }),
            winner,
        }
    }
}

export default getPostSetEventProps
