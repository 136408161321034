import { useCallback, useRef } from 'react'
import { ResultTeaserCompetitionProps, TrackingDataProps, MetaDataProps } from '@sport1/types/web'
import { WidgetTrackingIdentifier } from '@sport1/bets-service-client'
import { CompetitionClientModel, SportGroupClientModel } from '@sport1/types/sportData'
import useBettingTracking, { BettingTrackingProps } from '@/hooks/useBettingTracking'
import { ViewableItemsChanged } from '@/components/HorizontalSlider/types'
import { mapTrackingData, useTracking } from '@/utils/tracking/TrackingProvider'
import { TrackingCreationType } from '@/components/ResultTeaser'

export type UseResultTeaserTrackingParams = {
    trackingCreation: WidgetTrackingIdentifier
    componentTrackingData: MetaDataProps['tracking']
    competitionCards: ResultTeaserCompetitionProps[]
    componentIndex: number
    category: SportGroupClientModel
    categoryPosition: number
}

export type TrackingProps = {
    competition?: CompetitionClientModel
    positionInSlider: number
    type: TrackingCreationType
    competitionCardTrackingData?: TrackingDataProps[]
}

export type UseResultTeaserTrackingReturnProps = {
    trackMatchCardImpression: (props: TrackingProps) => void
    trackMatchCardClick: (props: TrackingProps) => void
    trackBetImpressionOnView: (props: BettingTrackingProps) => void
    onViewableItemsChanged: (info: ViewableItemsChanged<ResultTeaserCompetitionProps>) => void
}

type TrackingDeferred = { [matchId: string]: Function }
type MatchViewed = { [matchId: string]: boolean }

const useResultTeaserTracking = ({
    trackingCreation,
    componentTrackingData = [],
    competitionCards,
    componentIndex,
    category,
    categoryPosition,
}: UseResultTeaserTrackingParams): UseResultTeaserTrackingReturnProps => {
    const { trackBetImpression } = useBettingTracking(trackingCreation)
    const deferredBetTracking = useRef<TrackingDeferred>({})
    const viewedMatchCard = useRef<MatchViewed>({})
    const viewedBet = useRef<MatchViewed>({})
    const { trackTeaserImpression, trackTeaserClick } = useTracking()

    const createResultTeaserTrackingData = useCallback(
        ({
            competition,
            positionInSlider,
            type,
            competitionCardTrackingData = [],
        }: TrackingProps) => {
            const mappedTrackingData = mapTrackingData([
                ...competitionCardTrackingData,
                ...componentTrackingData,
            ])
            return {
                advertiser_id:
                    mappedTrackingData.advertiser_id || `${competition?.id?.replace('opta_', '')}`,
                campaign: mappedTrackingData.campaign || 'result-teaser',
                creation: mappedTrackingData.creation
                    ? mappedTrackingData.creation.replace('slider', type)
                    : `result-teaser | ${type}`,
                format: mappedTrackingData.format
                    ? `${mappedTrackingData.format} | ${category.name} | ${categoryPosition}`
                    : `manual | ${category.name} | ${categoryPosition}`,
                target_url: mappedTrackingData.url || `${competition?.name}`,
                variant: `${componentIndex + 1} | ${positionInSlider + 1} | ${
                    mappedTrackingData.variant || 'result-teaser'
                }`,
            }
        },
        [category.name, categoryPosition, componentIndex, componentTrackingData]
    )

    const trackMatchCardImpression = useCallback(
        (props: TrackingProps) => {
            trackTeaserImpression(createResultTeaserTrackingData(props))
        },
        [trackTeaserImpression, createResultTeaserTrackingData]
    )

    const trackMatchCardClick = useCallback(
        (props: TrackingProps) => {
            trackTeaserClick(createResultTeaserTrackingData(props))
        },
        [trackTeaserClick, createResultTeaserTrackingData]
    )

    /**
     * Intercept trackBetImpression by <ResultTeaserCard />
     * Check if already in view, otherwise defer
     */
    const trackBetImpressionOnView = useCallback(
        (props: BettingTrackingProps) => {
            const id = props.match?.id
            if (trackBetImpression && id) {
                if (viewedBet.current[id]) {
                    trackBetImpression(props)
                } else {
                    deferredBetTracking.current[id] = () => trackBetImpression(props)
                }
            }
        },
        [trackBetImpression]
    )

    const onViewableItemsChanged = useCallback(
        (info: ViewableItemsChanged<ResultTeaserCompetitionProps>) => {
            info.viewableItems.forEach(({ item }) => {
                const tracking = item?.meta?.tracking
                const firstMatch = item?.matches?.[0]

                if (firstMatch && !viewedMatchCard.current[firstMatch.id as string]) {
                    if (trackMatchCardImpression) {
                        trackMatchCardImpression({
                            competition: firstMatch.competition,
                            positionInSlider:
                                competitionCards?.findIndex(
                                    competition => competition.matches?.[0]?.id === firstMatch.id
                                ) ?? -1,
                            type: 'slider',
                            competitionCardTrackingData: tracking,
                        })
                    }

                    viewedMatchCard.current[firstMatch.id as string] = true
                }

                item?.matches?.forEach(c => {
                    const id = c?.id as string
                    viewedBet.current[id] = true
                    if (id && deferredBetTracking.current[id]) {
                        deferredBetTracking.current[id]()
                    }
                })
            })
        },
        [competitionCards, trackMatchCardImpression]
    )

    return {
        onViewableItemsChanged,
        trackBetImpressionOnView,
        trackMatchCardImpression,
        trackMatchCardClick,
    }
}

export default useResultTeaserTracking
