import { MatchClientModel } from '@sport1/types/sportData'
import { PostEventProps } from '@sport1/news-styleguide/Types'
import getResults from './getResults'

const getPostEventProps = (
    { results }: MatchClientModel,
    eventHref?: string,
    onPressEvent?: () => void
): PostEventProps | undefined => {
    if (!results || !results.length) return undefined
    return {
        type: 'POST',
        eventHref,
        playersProp: getResults(results.slice(0, 3), eventHref, onPressEvent),
    }
}

export default getPostEventProps
