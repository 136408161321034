import { MatchClientModel } from '@sport1/types/sportData'
import { LiveEventProps } from '@sport1/news-styleguide/Types'
import { getTimeWithHourAndMinutes } from '@/utils/date'
import getMatchInfoProps from '@/utils/match/getMatchInfoProps'
import getSportIdentifier from '@/utils/match/getSportIdentifier'
import { MatchUsageSource } from '@/components/SportEvent/utils'

const getLiveEventProps = ({
    match,
    eventHref,
    onPressMore,
    source,
}: {
    match: MatchClientModel
    eventHref?: string
    onPressMore?: () => void
    source: MatchUsageSource
}): LiveEventProps => {
    return {
        type: 'LIVE',
        eventStartTime: match.scheduledStartTime
            ? `${getTimeWithHourAndMinutes(match.scheduledStartTime)} Uhr`
            : '',
        caption: getMatchInfoProps({
            resultType: match.matchInfo?.resultType,
            sportIdentifier: getSportIdentifier(match),
            source,
        }),
        eventHref,
        onPressMore,
    }
}

export default getLiveEventProps
