import { ResultTeaserContentProps, TagProps } from '@sport1/types/web'
import React, { useEffect } from 'react'
import useFetch, { UseFetchReturnProps } from '@/hooks/useFetch'
import getOptimizedTag from '@/utils/urlTranslator/getOptimizedTag'

const useResultTeaserGenericContent = (
    url: string
): UseFetchReturnProps<ResultTeaserContentProps> => {
    const [dataWithTags, setDataWithTags] = React.useState<ResultTeaserContentProps>()
    const { data, error, loading, fetchData } = useFetch<ResultTeaserContentProps>(url)

    const extendTags = React.useCallback(async (): Promise<
        ResultTeaserContentProps | undefined
    > => {
        if (data) {
            for (const val of data.content) {
                val.tags?.length && (await getOptimizedTag(val.tags[0] as TagProps))
            }
        }

        return data
    }, [data])

    useEffect(() => {
        extendTags().then(setDataWithTags)
    }, [extendTags])

    useEffect(() => {
        setDataWithTags(undefined)
    }, [url])

    return { data: dataWithTags, error, loading, fetchData }
}

export default useResultTeaserGenericContent
