import { MatchClientModel } from '@sport1/types/sportData'
import { CompetitorProps } from '@sport1/news-styleguide/Types'
import dayjs from 'dayjs'
import getSportEventStatus from '@/utils/match/getSportEventStatus'
import {
    getMatchResultProps,
    getTeamImageUrl,
    getTeamName,
    LiveMatchProps,
    MatchUsageSource,
    PostMatchProps,
    PreMatchProps,
} from '@/components/SportEvent/utils'
import getSportIdentifier from '@/utils/match/getSportIdentifier'
import getMatchInfoProps from '@/utils/match/getMatchInfoProps'
import getSportEventWinnerProp from '@/utils/match/getSportEventWinnerProp'
import { getRelatedMatchLabel } from '@/utils/match/getRelatedMatchLabel'
import { getPlayoffLabel } from '@/utils/match/getPlayoffLabel'
import { createTeamHref } from '@/utils/navigation/Navigator'

const getProgressPercent = (match: MatchClientModel): number | undefined => {
    if (match.matchInfo?.matchTime) {
        return Math.min(Math.ceil((match.matchInfo?.matchTime * 100) / 90), 100)
    }
    return undefined
}

const getResultProps = (
    resultType: string,
    source: MatchUsageSource = 'default'
): string | undefined => {
    switch (resultType) {
        case 'POSTPONED':
            return 'Verlegt'
        case 'ABANDONED':
            return source === 'default' ? 'Abgebr.' : 'Abgebrochen'
        case 'CANCELLED':
            return 'Abgesagt'
        case 'AFTER_EXTRA_TIME':
            return 'n.V.'
        case 'AFTER_PENALTY':
            return 'n.E.'
        case 'PENALTY_SHOOTOUT':
            return 'i.E.'
        case 'WALKOVER':
            return 'Walkover'
    }
    return undefined
}

export const getBaseMatchProps = (
    match: MatchClientModel,
    showTeamFullName?: boolean
): {
    homeCompetitor: CompetitorProps
    awayCompetitor: CompetitorProps
    additionalField2?: string
} | null => {
    const { homeTeam, awayTeam, competition, sport } = match
    const homeName = getTeamName(homeTeam, showTeamFullName)
    const awayName = getTeamName(awayTeam, showTeamFullName)
    const additionalField2 = getRelatedMatchLabel(match) || getPlayoffLabel(match)
    if (homeName && awayName) {
        return {
            homeCompetitor: {
                href: createTeamHref(homeTeam),
                logoUrl:
                    getTeamImageUrl({
                        team: homeTeam,
                        sportIdentifier: getSportIdentifier({ competition, sport }),
                    }) ?? '',
                name: homeName,
            },
            awayCompetitor: {
                href: createTeamHref(awayTeam),
                logoUrl:
                    getTeamImageUrl({
                        team: awayTeam,
                        sportIdentifier: getSportIdentifier({ competition, sport }),
                    }) ?? '',
                name: awayName,
            },
            additionalField2,
        }
    }
    return null
}

export const getPreMatchProps = (
    match: MatchClientModel,
    source: MatchUsageSource,
    showTeamFullName?: boolean
): PreMatchProps => {
    const baseMatchProps = getBaseMatchProps(match, showTeamFullName)
    if (baseMatchProps) {
        let caption = getResultProps(match.matchInfo?.resultType || '')
        if (!caption) {
            caption = match.dateTbd
                ? '-:-'
                : match.scheduledStartTime && dayjs(match.scheduledStartTime).tz().format('HH:mm')
        }
        return {
            type: 'PRE',
            ...baseMatchProps,
            additionalField1:
                source === 'live-ticker' && match.matchInfo?.resultType === 'DELAYED'
                    ? getMatchInfoProps({ resultType: match.matchInfo?.resultType })
                    : undefined,
            caption: caption || '',
        }
    }
}

export const getLiveMatchProps = (
    match: MatchClientModel,
    showTeamFullName?: boolean
): LiveMatchProps => {
    const baseMatchProps = getBaseMatchProps(match, showTeamFullName)
    if (baseMatchProps) {
        return {
            type: 'LIVE',
            ...baseMatchProps,
            ...getMatchResultProps(match),
            progressPercent: getProgressPercent(match),
            caption: match.matchInfo?.matchMinute,
        }
    }
}

// TODO: double check this implementation of NewsOne
export const getPostMatchProps = (
    match: MatchClientModel,
    source: MatchUsageSource,
    showTeamFullName?: boolean
): PostMatchProps => {
    const baseMatchProps = getBaseMatchProps(match, showTeamFullName)
    if (baseMatchProps) {
        if (
            (match.homePenaltyScore || match.awayPenaltyScore) &&
            match.matchInfo?.resultType !== 'AFTER_PENALTY'
        ) {
            match.matchInfo = { ...match.matchInfo, resultType: 'AFTER_PENALTY' }
        }
        const additionalField1 = getMatchInfoProps({
            resultType: match.matchInfo?.resultType,
            sportIdentifier: getSportIdentifier(match),
            source,
        })

        return {
            type: 'POST',
            ...getMatchResultProps(match),
            winner: getSportEventWinnerProp(match),
            additionalField1,
            ...baseMatchProps,
        }
    }
}

const getSpecifiedMatchProps = (
    match: MatchClientModel,
    source: MatchUsageSource,
    showTeamFullName?: boolean
) => {
    const resultType = match?.matchInfo?.resultType
    if (resultType === 'ABANDONED' && source === 'live-ticker') {
        return getPostMatchProps(match, source, showTeamFullName)
    }
    return getPreMatchProps(match, source, showTeamFullName)
}

type GetMatchPropsType = {
    match: MatchClientModel
    showTeamFullName?: boolean
    source?: MatchUsageSource
}

type MatchProps = ReturnType<
    | typeof getPreMatchProps
    | typeof getLiveMatchProps
    | typeof getPostMatchProps
    | typeof getSpecifiedMatchProps
>
export const getMatchProps = ({
    match,
    showTeamFullName,
    source = 'default',
}: GetMatchPropsType): MatchProps => {
    const status = getSportEventStatus(match)
    if (status === 'PRE') {
        return getPreMatchProps(match, source, showTeamFullName)
    } else if (status === 'LIVE') {
        return getLiveMatchProps(match, showTeamFullName)
    } else if (status === 'POST') {
        return getPostMatchProps(match, source, showTeamFullName)
    } else if (status === 'SPECIFIED') {
        return getSpecifiedMatchProps(match, source, showTeamFullName)
    }
}
