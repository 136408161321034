import { MatchClientModel } from '@sport1/types/sportData'
import { LiveSetRowProps } from '@sport1/news-styleguide/Types'
import { getSportEventTeamProps, MatchUsageSource } from '@/components/SportEvent/utils'
import getSetResultProps from '@/components/SportEvent/Set/util/getSetResultProps'

const getLiveSetEventProps = (
    event: MatchClientModel,
    source?: MatchUsageSource
): Omit<LiveSetRowProps, 'onPressMatch'> | undefined => {
    const baseProps = getSportEventTeamProps(event)
    const setResultProps = getSetResultProps({ match: event, source })
    if (baseProps && setResultProps) {
        return {
            type: 'LIVE',
            ...baseProps,
            ...setResultProps,
            periods: setResultProps.periods || [],
        }
    }
}

export default getLiveSetEventProps
