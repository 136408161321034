import { PlayerClientModel, ResultClientModel } from '@sport1/types/sportData'
import Types from '@sport1/news-styleguide/Types'
import { getTeamName } from '@/components/SportEvent/utils'
import getPlayerName from '@/utils/sportsData/getPlayerName'

const getResultMultiplePersonName = (persons: PlayerClientModel[]): string =>
    persons
        .reduce((result, item) => {
            return `${result} / ${item.lastName}`
        }, '')
        .substring(3)

const getResultValue = ({ points, time, fastestLap }: ResultClientModel): string | null => {
    const timeString = time || fastestLap
    if (timeString) {
        let unit = ''
        /**
         * Check if timeString contains any characters, if it does, don't treat it as time
         * e.g.: "+1 Runde" --> doesn't need a unit
         * */
        if ((timeString.match(/[A-Za-z]/) || []).length) {
            return timeString
        }
        const count = (timeString.match(/:/g) || []).length
        if (count === 1) {
            unit = 'm'
        } else if (count === 2) {
            unit = 'h'
        } else {
            unit = 's'
        }
        return timeString.concat(unit)
    } else if (points) {
        return `${points} Pkt.`
    }
    return null
}

const getResult = (
    result: ResultClientModel,
    eventHref?: string,
    onPressEvent?: () => void
): Types.SoloPlayer | null => {
    const { person, persons, team: resultTeam, position = 1 } = result
    const resultValue = getResultValue(result)
    const team = person?.team || resultTeam
    let personName = team?.shortName || team?.name
    let imageUrl
    if (person) {
        personName = getPlayerName(person, 'firstname lastname')
        imageUrl = person.nationality?.imageUrl
    } else if (persons) {
        personName = getResultMultiplePersonName(persons)
        imageUrl = resultTeam?.nationality?.imageUrl
    }
    if (position && personName && imageUrl) {
        const teamName = getTeamName(team)
        return {
            position,
            playerName: personName,
            playerImage: imageUrl,
            winTime: resultValue || '',
            eventHref,
            caption: teamName,
            onPressEvent,
        }
    }
    return null
}

const getResults = (
    results: ResultClientModel[],
    eventHref?: string,
    onPressEvent?: () => void
): Types.SoloPlayer[] => {
    const persons: Types.SoloPlayer[] = []

    results.forEach(result => {
        const person = getResult(result, eventHref, onPressEvent)
        if (person) {
            persons.push(person)
        }
    })
    return persons
}

export default getResults
