import { ActionButtonProps, LabelProps, TagProps } from '@sport1/types/web'
import { MatchClientModel } from '@sport1/types/sportData'
import {
    createCalendarHref,
    createGameplanHref,
    createStandingsHref,
    createTourDeFranceResultsHref,
} from '@/utils/navigation/Navigator'
import isEventBasedSportEvent from '@/utils/match/isEventBasedSportEvent'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

const getActionButtons = ({
    hasStandings,
    matches,
    sportIdentifier,
    sportId,
    tag,
}: {
    hasStandings: boolean
    matches: MatchClientModel[]
    sportIdentifier?: string
    sportId?: string
    tag?: TagProps | LabelProps
}): Omit<ActionButtonProps, 'type'>[] => {
    const buttons: Omit<ActionButtonProps, 'type'>[] = []
    if (!tag) {
        return buttons
    }

    const competitionId = matches[0]?.competition?.id
    if (isEventBasedSportEvent(sportIdentifier)) {
        let buttonProps = { title: 'KALENDER', href: createCalendarHref({ tag, sportId }) }
        if (sportIdentifier === sportIdentifiers.cycling.name) {
            const competitionName = matches[0]?.competition?.name
            const sportGroupName = matches[0]?.competition?.sportGroup?.name
            if (competitionName === 'Tour de France') {
                buttonProps = {
                    title: 'Ergebnisse',
                    href: createTourDeFranceResultsHref({ competitionName, sportGroupName }),
                }
            }
        }
        buttons.push(buttonProps)
    } else {
        if (hasStandings) {
            buttons.push({
                title: 'TABELLE',
                href: createStandingsHref({ tag, competitionId }),
            })
        }

        buttons.push({
            title: 'SPIELPLAN',
            href: createGameplanHref({ tag, competitionId }),
        })
    }

    return buttons
}

export default getActionButtons
