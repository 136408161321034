import { MatchClientModel } from '@sport1/types/sportData'
import getPostEventProps from '@/components/SportEvent/Event/util/getPostEventProps'
import getPreEventProps from '@/components/SportEvent/Event/util/getPreEventProps'
import { MatchUsageSource } from '@/components/SportEvent/utils'

type SpecifiedEventProps = ReturnType<typeof getPostEventProps | typeof getPreEventProps>

const getSpecifiedEventProps = ({
    match,
    eventHref,
    onPressMore,
    source,
}: {
    match: MatchClientModel
    eventHref?: string
    onPressMore?: () => void
    source: MatchUsageSource
}): SpecifiedEventProps => {
    const resultType = match?.matchInfo?.resultType
    if (resultType === 'ABANDONED') {
        getPostEventProps(match)
    }
    return getPreEventProps({ match, eventHref, onPressMore, source })
}

export default getSpecifiedEventProps
