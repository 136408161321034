import { MatchClientModel } from '@sport1/types/sportData'
import { getPreMatchProps } from '@/components/SportEvent/Match/utils'
import { MatchUsageSource } from '@/components/SportEvent/utils'
import getLiveSetEventProps from '@/components/SportEvent/Set/util/getLiveSetEventProps'
import getPostSetEventProps from '@/components/SportEvent/Set/util/getPostSetEventProps'
import getSportEventStatus from '@/utils/match/getSportEventStatus'
import getSpecifiedSetProps from '@/components/SportEvent/Set/util/getSpecifiedSetProps'

type SetProps = ReturnType<
    | typeof getPreMatchProps
    | typeof getLiveSetEventProps
    | typeof getPostSetEventProps
    | typeof getSpecifiedSetProps
>

const getSetEventProps = ({
    event,
    source = 'default',
    showTeamFullName,
}: {
    event: MatchClientModel
    source?: MatchUsageSource
    showTeamFullName?: boolean
}): SetProps => {
    const period = getSportEventStatus(event)
    if (period === 'PRE') {
        return getPreMatchProps(event, source, showTeamFullName)
    } else if (period === 'LIVE') {
        return getLiveSetEventProps(event, source)
    } else if (period === 'POST') {
        return getPostSetEventProps(event, source)
    } else if (period === 'SPECIFIED') {
        return getSpecifiedSetProps({ event, source, showTeamFullName })
    }
}

export default getSetEventProps
