import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import React, { ReactElement, useMemo } from 'react'
import { Spacing } from '@sport1/news-styleguide/LayoutTypes'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import { useOddsDisclaimerContext } from '@/utils/oddsDisclaimer/OddsDisclaimerProvider'
import { sport1Regular } from '@/helpers/fonts'

interface OddsDisclaimerProps extends SystemSpacingsProps {
    spaceX?: Spacing
    testID?: string
    textAlign?: 'center' | 'left' | 'right'
}

const OddsDisclaimer = ({
    spaceX = 'small',
    testID,
    textAlign = 'right',
    marginTop,
    marginBottom,
}: OddsDisclaimerProps): ReactElement | null => {
    const { needsDisclaimer, hasOdds } = useOddsDisclaimerContext()

    const GAIN_INFO = '10 € Einsatz'

    const additionalMessage = useMemo(() => {
        const LEGAL_INFO =
            '18+ | Erlaubt (Whitelist) | Suchtrisiko | Hilfe unter www.buwei.de | -w-'
        if (needsDisclaimer) {
            return `${GAIN_INFO} | ${LEGAL_INFO}`
        }
        return `${LEGAL_INFO}`
    }, [needsDisclaimer])

    return hasOdds ? (
        <NonFlexingContainer
            horizontal
            marginTop={marginTop}
            marginBottom={marginBottom}
            spaceX={spaceX}
            testID={testID}
        >
            <FlexingContainer>
                <Font
                    fontVariant="Label-M"
                    fontFamilyVariant="Sport1-Regular"
                    nextFontFamilyVariant={sport1Regular.style.fontFamily}
                    textAlign={textAlign}
                    color="keshi-3"
                >
                    {additionalMessage}
                </Font>
            </FlexingContainer>
        </NonFlexingContainer>
    ) : null
}

export default OddsDisclaimer
