import React, { FunctionComponent, memo, ReactElement, useMemo } from 'react'
import LightMatchLib from '@sport1/news-styleguide/LightMatch'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { MatchClientModel } from '@sport1/types/sportData'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import { getBaseMatchProps } from '@/components/SportEvent/Match/utils'

export interface MatchRowProps extends SystemSpacingsProps {
    match: MatchClientModel
}

const LightMatch: FunctionComponent<MatchRowProps> = ({
    match,
    paddingX,
}: MatchRowProps): ReactElement | null => {
    const matchProps = useMemo(() => getBaseMatchProps(match), [match])
    if (matchProps) {
        return (
            <NonFlexingContainer height={40} paddingX={paddingX}>
                <LightMatchLib {...matchProps} />
            </NonFlexingContainer>
        )
    }

    return null
}

export default memo(LightMatch)
