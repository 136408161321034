import { MatchClientModel } from '@sport1/types/sportData'
import { SetPeriodProps } from '@sport1/news-styleguide/Types'
import getMatchInfoProps from '@/utils/match/getMatchInfoProps'
import { MatchUsageSource } from '@/components/SportEvent/utils'

const getSetResultProps = ({
    match,
    source,
}: {
    match: MatchClientModel
    source?: MatchUsageSource
}): {
    caption?: string
    homeScore: number
    awayScore: number
    periods?: SetPeriodProps[]
} => {
    const { matchInfo, awayScore, homeScore, periods } = match
    const isLegBased =
        matchInfo?.bestOfSets === 1 && matchInfo?.bestOfLegs && matchInfo?.bestOfLegs > 1
    const bestOf = !isLegBased ? matchInfo?.bestOfSets : matchInfo?.bestOfLegs
    const hidePeriods = isLegBased && matchInfo?.bestOfLegs && matchInfo.bestOfLegs > 5
    const caption = getMatchInfoProps({ resultType: matchInfo?.resultType, source })
    return {
        caption: caption || (bestOf ? `Best of ${bestOf}` : undefined),
        awayScore: awayScore || 0,
        homeScore: homeScore || 0,
        periods: !hidePeriods ? (periods as SetPeriodProps[]) : undefined,
    }
}

export default getSetResultProps
