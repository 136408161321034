import { FC } from 'react'
import { HeadlineProps } from '@sport1/news-styleguide/MatchCard'
import EventCard from '@sport1/news-styleguide/EventCard'
import getEventProps from './util/getEventProps'
import { SportEventProps } from '@/components/SportEvent'

type EventProps = Pick<
    SportEventProps,
    'match' | 'headline' | 'spaceTop' | 'spaceY' | 'spaceBottom' | 'trackCardClick' | 'testID'
> & {
    onPressMore?: () => void
    matchHref?: string
}

const Event: FC<EventProps> = ({
    match,
    headline,
    spaceY,
    spaceTop,
    spaceBottom,
    testID,
    onPressMore,
    matchHref,
    trackCardClick,
}) => {
    const eventProps = getEventProps({
        match,
        onPressMore,
        eventHref: matchHref,
        onPressEvent: trackCardClick,
    })
    const headlineProps = headline
        ? ({
              ...headline,
              href: matchHref,
          } as HeadlineProps)
        : undefined

    return eventProps ? (
        <EventCard
            testID={testID}
            eventProps={{ ...eventProps, onPressEvent: matchHref ? trackCardClick : undefined }}
            headlineProps={headlineProps}
            spaceBottom={spaceBottom}
            spaceTop={spaceTop}
            spaceY={spaceY}
        />
    ) : null
}

export default Event
