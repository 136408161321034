import { MatchClientModel } from '@sport1/types/sportData'
import isEventBasedSportEvent from '@/utils/match/isEventBasedSportEvent'
import getSportIdentifier from '@/utils/match/getSportIdentifier'

const getHeadline = (match?: MatchClientModel): string => {
    const values: string[] = []

    if (match && isEventBasedSportEvent(getSportIdentifier(match)) && match.sport?.name) {
        values.push(match.sport.name)
    }
    if (match?.competition?.name) {
        values.push(match.competition.name)
    }
    if (match?.roundTitle) {
        values.push(match.roundTitle)
    }

    return values.join(', ')
}

export default getHeadline
