import React, { VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { LabelProps, TagProps, TrackingDataProps } from '@sport1/types/web'
import { MatchClientModel } from '@sport1/types/sportData'
import SportEvent from '@/components/SportEvent'
import { ResultTeaserCompetitionCardProps } from '@/components/ResultTeaserGeneric/CompetitionCard'

type CompetitionCardMatchesProps = Pick<
    ResultTeaserCompetitionCardProps,
    | 'forceMobileView'
    | 'minify'
    | 'trackBetImpressionOnView'
    | 'trackMatchCardClick'
    | 'trackingCreation'
    | 'positionInSlider'
    | 'variant'
    | 'testID'
> & {
    competitionCardTrackingData?: TrackingDataProps[]
    matches: MatchClientModel[]
    tag?: TagProps | LabelProps
    trackingInteractionLabel: string
}

const CompetitionCardMatches: VFC<CompetitionCardMatchesProps> = ({
    matches,
    trackMatchCardClick,
    tag,
    trackBetImpressionOnView,
    trackingCreation,
    minify,
    forceMobileView,
    positionInSlider = 0,
    variant,
    trackingInteractionLabel,
    competitionCardTrackingData,
    testID,
}) => {
    return (
        <NonFlexingContainer marginY="spacing-1" testID={testID}>
            {matches.map((match, index) => (
                <SportEvent
                    forceMobileView={forceMobileView}
                    key={`${match.homeTeam?.shortName}_${match.awayTeam?.shortName}_${match.scheduledStartTime}`}
                    match={match}
                    minify={minify}
                    scope="ticker" // TODO: correct scope?
                    spaceBottom={index !== matches.length - 1 ? 'xsmall' : 'none'}
                    tag={tag}
                    testID={`result_teaser_match_card_${match.id}`}
                    trackBetImpression={trackBetImpressionOnView}
                    trackCardClick={() =>
                        trackMatchCardClick?.({
                            competition: match.competition,
                            positionInSlider,
                            type: variant,
                            competitionCardTrackingData,
                        })
                    }
                    trackingCreation={trackingCreation}
                    trackingInteractionLabel={trackingInteractionLabel}
                />
            ))}
        </NonFlexingContainer>
    )
}

export default CompetitionCardMatches
