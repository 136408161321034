import React, { FC } from 'react'
import { GenericComponentProps, MetaDataProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import LightMatchCardSkeleton from '@sport1/news-styleguide/LightMatchCardSkeleton'
import { SportGroupClientModel } from '@sport1/types/sportData'
import Fallback from '@/components/ResultTeaserGeneric/Fallback'
import useResultTeaserGenericContent from '@/components/ResultTeaserGeneric/utils/useResultTeaserGenericContent'
import useTrackingCreation from '@/hooks/useTrackingCreation'
import useResultTeaserTracking from '@/components/ResultTeaserGeneric/utils/useResultTeaserTracking'
import SingleCompetition from '@/components/ResultTeaserGeneric/CompetitionList/Single'
import MultiCompetitions from '@/components/ResultTeaserGeneric/CompetitionList/Multi'
import { MatchOverlayProvider } from '@/utils/overlay/MatchOverlayProvider'
import { OddsDisclaimerContextProvider } from '@/utils/oddsDisclaimer/OddsDisclaimerProvider'

type ResultTeaserCompetitionListProps = Pick<
    GenericComponentProps,
    'contentUrl' | 'fragmentMeta'
> & {
    componentTrackingData?: MetaDataProps['tracking']
    testID?: string
    componentIndex?: number
    isRightColumn?: boolean
    category: SportGroupClientModel
    categoryPosition: number
}

const ResultTeaserCompetitionList: FC<ResultTeaserCompetitionListProps> = ({
    contentUrl,
    testID,
    fragmentMeta,
    componentTrackingData,
    componentIndex = 0,
    isRightColumn,
    category,
    categoryPosition,
}) => {
    const { trackingCreation } = useTrackingCreation('inline_resultteaser')
    const { data, loading, error } = useResultTeaserGenericContent(contentUrl)

    const {
        trackMatchCardClick,
        trackMatchCardImpression,
        trackBetImpressionOnView,
        onViewableItemsChanged,
    } = useResultTeaserTracking({
        competitionCards: data?.content || [],
        trackingCreation,
        componentIndex,
        componentTrackingData,
        category,
        categoryPosition,
    })

    if (!error && (!data || loading)) {
        return (
            <NonFlexingContainer
                testID={`${testID}-loading`}
                backgroundColor="pearl"
                marginX={
                    isRightColumn
                        ? 'spacing-none'
                        : ['spacing-6', 'spacing-6', 'spacing-9', 'spacing-10']
                }
                paddingX={
                    isRightColumn
                        ? 'spacing-none'
                        : ['spacing-6', 'spacing-6', 'spacing-9', 'spacing-10']
                }
            >
                <LightMatchCardSkeleton testID={`${testID}-skeleton`} />
            </NonFlexingContainer>
        )
    }
    if (error || !data) {
        return (
            <NonFlexingContainer
                marginX={
                    isRightColumn
                        ? 'spacing-none'
                        : ['spacing-6', 'spacing-6', 'spacing-9', 'spacing-10']
                }
            >
                <Fallback
                    isRightColumn={isRightColumn}
                    testID={testID}
                    text="Beim Laden der Events ist leider ein Fehler aufgetreten."
                />
            </NonFlexingContainer>
        )
    }
    if (!data.content.length) {
        return (
            <NonFlexingContainer
                marginX={
                    isRightColumn
                        ? 'spacing-none'
                        : ['spacing-6', 'spacing-6', 'spacing-9', 'spacing-10']
                }
            >
                <Fallback
                    isRightColumn={isRightColumn}
                    testID={testID}
                    text="Keine Events verfügbar."
                />
            </NonFlexingContainer>
        )
    }

    return (
        <OddsDisclaimerContextProvider>
            <MatchOverlayProvider paddingX="spacing-4">
                <NonFlexingContainer
                    testID={testID}
                    marginX={
                        isRightColumn
                            ? 'spacing-none'
                            : ['spacing-6', 'spacing-6', 'spacing-9', 'spacing-10']
                    }
                >
                    {data.content.length === 1 ? (
                        <SingleCompetition
                            testID={testID}
                            trackMatchCardImpression={trackMatchCardImpression}
                            trackMatchCardClick={trackMatchCardClick}
                            trackingCreation={trackingCreation}
                            competitionCard={data.content[0]}
                            fragmentMeta={fragmentMeta}
                        />
                    ) : (
                        <MultiCompetitions
                            isRightColumn={isRightColumn}
                            competitionCards={data.content}
                            trackMatchCardClick={trackMatchCardClick}
                            trackingCreation={trackingCreation}
                            testID={testID}
                            onViewableItemsChanged={onViewableItemsChanged}
                            trackBetImpressionOnView={trackBetImpressionOnView}
                            fragmentMeta={fragmentMeta}
                        />
                    )}
                </NonFlexingContainer>
            </MatchOverlayProvider>
        </OddsDisclaimerContextProvider>
    )
}

export default ResultTeaserCompetitionList
