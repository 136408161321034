import { MatchClientModel } from '@sport1/types/sportData'
import { PreEventProps } from '@sport1/news-styleguide/Types'
import { getTimeWithHourAndMinutes } from '@/utils/date'
import getMatchInfoProps from '@/utils/match/getMatchInfoProps'
import getSportIdentifier from '@/utils/match/getSportIdentifier'
import { MatchUsageSource } from '@/components/SportEvent/utils'

const getPreEventProps = ({
    match,
    eventHref,
    onPressMore,
    source,
}: {
    match: MatchClientModel
    eventHref?: string
    onPressMore?: () => void
    source: MatchUsageSource
}): PreEventProps => {
    let eventStartTime = ''
    if (match.dateTbd) {
        eventStartTime = '-:-'
    } else if (match.scheduledStartTime) {
        eventStartTime = `${getTimeWithHourAndMinutes(match.scheduledStartTime)} Uhr`
    }

    return {
        type: 'PRE',
        eventStartTime,
        caption: getMatchInfoProps({
            resultType: match.matchInfo?.resultType,
            sportIdentifier: getSportIdentifier(match),
            source,
        }),
        eventHref,
        onPressMore,
    }
}

export default getPreEventProps
