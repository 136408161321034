import { MatchClientModel } from '@sport1/types/sportData'
import getPostSetEventProps from '@/components/SportEvent/Set/util/getPostSetEventProps'
import { MatchUsageSource } from '@/components/SportEvent/utils'
import { getPreMatchProps } from '@/components/SportEvent/Match/utils'

type SpecifiedSetProps = ReturnType<typeof getPostSetEventProps | typeof getPreMatchProps>

const getSpecifiedSetProps = ({
    event,
    source,
    showTeamFullName,
}: {
    event: MatchClientModel
    source: MatchUsageSource
    showTeamFullName?: boolean
}): SpecifiedSetProps => {
    const resultType = event?.matchInfo?.resultType
    if (resultType === 'ABANDONED' && source === 'live-ticker') {
        return getPostSetEventProps(event, source)
    }
    return getPreMatchProps(event, source, showTeamFullName)
}

export default getSpecifiedSetProps
