import React, { useMemo, useRef, VFC } from 'react'
import LibPartnerButton from '@sport1/news-styleguide/PartnerButton'
import { FragmentMetaModel, FragmentMetaType } from '@sport1/types/web'
import { CompetitionClientModel, SportClientModel } from '@sport1/types/sportData'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import useInViewport from '@/hooks/useInViewport'
import shouldShowPartnerButton from '@/components/PartnerButton/utils'

export type PartnerButtonProps = {
    sport?: SportClientModel
    competition?: CompetitionClientModel
    fragmentMeta?: FragmentMetaModel[]
}

const PartnerButton: VFC<PartnerButtonProps> = ({ competition, sport, fragmentMeta }) => {
    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useInViewport(ref)
    const { trackTeaserImpression, trackTeaserClick } = useTracking()

    const partnerButton = useMemo(
        () =>
            fragmentMeta?.find(
                m =>
                    m.type === FragmentMetaType.PARTNER_BUTTON &&
                    shouldShowPartnerButton({
                        sportId: sport?.id,
                        competitionId: competition?.id,
                        ...m.config,
                    })
            ),
        [competition?.id, fragmentMeta, sport?.id]
    )

    const trackingData = useMemo(
        () => ({
            campaign: 'ad',
            creation: 'partner_button',
            variant: competition?.name,
            format: 'result-teaser',
            advertiser_id: partnerButton?.config.text,
            target_url: partnerButton?.config.trackingLink,
        }),
        [competition?.name, partnerButton?.config]
    )

    React.useEffect(() => {
        if (isVisible) {
            trackTeaserImpression(trackingData)
        }
    }, [isVisible, trackTeaserImpression, trackingData])

    if (
        !partnerButton?.config.text ||
        !partnerButton.config.trackingLink ||
        !partnerButton.config.imageUrl ||
        (partnerButton.config.showAtWeekDays &&
            !partnerButton.config.showAtWeekDays.includes(new Date().getDay()))
    ) {
        return null
    }

    const { text, trackingLink, imageTrackingLink, imageUrl } = partnerButton.config

    return (
        <div ref={ref}>
            <LibPartnerButton
                alignRight
                image={{
                    alt: 'Werbung für Partner',
                    url: imageUrl,
                }}
                onPress={() => {
                    trackTeaserClick(trackingData)
                }}
                onImagePress={() => {
                    trackTeaserClick(trackingData)
                }}
                buttonText={text}
                linkUrl={trackingLink}
                imageLinkUrl={imageTrackingLink}
            />
        </div>
    )
}

export default PartnerButton
