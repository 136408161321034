import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { LabelProps, TagProps } from '@sport1/types/web'
import { MatchClientModel } from '@sport1/types/sportData'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Bet from '@sport1/news-styleguide/Bet'
import {
    NavigationListItemProps,
    ResponsiveType,
    SystemSpacingsProps,
} from '@sport1/news-styleguide/Types'
import { BetsServiceScope, MarketType, WidgetTrackingIdentifier } from '@sport1/bets-service-client'
import dayjs from 'dayjs'
import LightMatch from '@/components/SportEvent/Overlay/LightMatch'
import { downloadIcs } from '@/helpers/calendarIcs'
import { getMatchStatus, MatchSheetViewType } from '@/components/SportEvent/utils'
import {
    createCalendarHref,
    createGameplanHref,
    createStandingsHref,
    getMatchLivetickerHref,
    LivetickerPage,
} from '@/utils/navigation/Navigator'
import { getMatchStatus as convertMatchStatus, useBetting } from '@/hooks/useBetting'
import useOddsRegistration from '@/hooks/useOddsRegistration'
import OddsDisclaimer from '@/components/OddsDisclaimer'
import Config from '@/utils/Config'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import nofollowExternal from '@/utils/url/nofollowExternal'
import getSportIdentifier from '@/utils/match/getSportIdentifier'
import sportIdentifiersConfig from '@/utils/sportIdentifiers/SportIdentifiersConfig'
import isEventBasedSportEvent from '@/utils/match/isEventBasedSportEvent'
import { useFindValueInNavigation } from '@/helpers/teamHelper'
import WrappedNavigationListItem from '@/components/WrappedNavigationListItem'

interface Props extends SystemSpacingsProps {
    match: MatchClientModel
    tag?: TagProps | LabelProps
    bettingGameURL?: string
    scope?: BetsServiceScope
    marketType?: MarketType
    trackingCreation: WidgetTrackingIdentifier
    top?: ResponsiveType<number>
    bottom?: ResponsiveType<number>
    onMouseOver?: () => void
    onMouseLeave?: () => void
    trackingInteractionLabel: string
}

const SportEventOverlay: FC<Props> = ({
    match,
    tag,
    bettingGameURL,
    marketType,
    scope,
    trackingCreation,
    top,
    bottom,
    onMouseOver,
    onMouseLeave,
    trackingInteractionLabel,
    paddingX,
}) => {
    const { trackInteraction, trackAdView } = useTracking()
    const trackedOpeningOverlay = useRef(false)

    const sportIdentifier = useMemo(() => getSportIdentifier(match), [match])
    const matchStatus = useMemo(() => getMatchStatus(match), [match])

    const bettingProperties = useBetting({
        match,
        marketType,
        scope,
        trackingCreation,
        ignoreMatchStatus: true,
    })

    useOddsRegistration({ matchId: match.id, odds: bettingProperties?.odds })

    const trackingLabel = useFindValueInNavigation('web-team-gameplan')
        ? 'team-matchdays'
        : undefined

    useEffect(() => {
        if (!trackedOpeningOverlay.current) {
            trackedOpeningOverlay.current = true
            trackInteraction({
                interaction_category: 'overlay',
                interaction_action: 'open',
                interaction_label: trackingLabel ? trackingLabel : trackingInteractionLabel,
                sportsdata_match: `${match.homeTeam?.name} - ${match.awayTeam?.name} | ${dayjs(
                    match.scheduledStartTime
                ).format('DD.MM.YYYY')}`,
                match_status: convertMatchStatus(match),
                page_sports_tags: `${match.sport?.name}`,
                page_competition_tags: `${match.competition?.name}`,
            })
        }
    }, [
        bettingProperties,
        match,
        trackingCreation,
        trackAdView,
        trackInteraction,
        trackingInteractionLabel,
        trackingLabel,
    ])

    const itemProps: NavigationListItemProps = {
        name: '',
        iconSize: 18,
        imageType: 'icon',
        rightIcon: 'scroll-right',
        rightIconColor: 'keshi-3',
    }

    const getLivetickerHref = useCallback(
        (page?: LivetickerPage): string =>
            getMatchLivetickerHref({
                match,
                tag,
                page,
            }) || '',
        [match, tag]
    )

    const trackClick = useCallback(
        (menuPoint: string): (() => void) => {
            return () =>
                trackInteraction({
                    interaction_category: 'overlay',
                    interaction_action: 'click',
                    interaction_label: trackingLabel
                        ? `${trackingLabel}_${menuPoint}`
                        : `${trackingInteractionLabel}_${menuPoint}`,
                    match_status: convertMatchStatus(match),
                    sportsdata_match: `${match.homeTeam?.name} - ${match.awayTeam?.name} | ${dayjs(
                        match.scheduledStartTime
                    ).format('DD.MM.YYYY')}`,
                    page_sports_tags: `${match.sport?.name}`,
                    page_competition_tags: `${match.competition?.name}`,
                })
        },
        [trackInteraction, trackingInteractionLabel, trackingLabel, match]
    )

    const downloadCalendarFile = useCallback(() => {
        const tracker = trackClick('download_calendar')
        tracker()
        const { homeTeam, awayTeam, competition } = match
        const title = `${homeTeam?.name} - ${awayTeam?.name}, ${competition?.name} Liveticker`
        const url = `${Config.HOST}${getLivetickerHref() ?? ''}`
        downloadIcs({
            filename: title,
            id: url,
            title,
            url,
            startDatetime: `${match.scheduledStartTime}`,
            duration: 2 * 60, // minutes
        })
    }, [getLivetickerHref, match, trackClick])

    if (!tag) {
        return null
    }

    return (
        <NonFlexingContainer
            position="relative"
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            <NonFlexingContainer
                noExpand
                overflow="auto"
                elevation={20}
                borderRadius="radius-m"
                backgroundColor="pearl"
                zIndex={400}
                // parentSlug ??
            >
                <LightMatch match={match} paddingX={paddingX} />
                {bettingProperties?.uiProperties ? (
                    <>
                        <Bet
                            {...bettingProperties.uiProperties}
                            onPressOdd={bettingProperties.onPressOdd}
                            onPressProvider={bettingProperties.onPressBetProvider}
                        />
                        <OddsDisclaimer textAlign="center" />
                    </>
                ) : null}
                <WrappedNavigationListItem
                    {...itemProps}
                    testID="match-overlay-navigation-item-news"
                    href={tag.href || ''}
                    onPress={trackClick('news')}
                    name="News"
                    imageIcon="news"
                    webRel={nofollowExternal}
                />
                {sportIdentifier === sportIdentifiersConfig.soccer.name &&
                match.hasTicker &&
                matchStatus !== MatchSheetViewType.PRE_MATCH ? (
                    <WrappedNavigationListItem
                        {...itemProps}
                        testID="match-overlay-navigation-item-statistics"
                        href={getLivetickerHref('statistik')}
                        onPress={trackClick('statistiken')}
                        name="Statistiken"
                        imageIcon="statistics"
                        webRel={nofollowExternal}
                    />
                ) : null}
                {match.hasTicker ? (
                    <WrappedNavigationListItem
                        {...itemProps}
                        testID="match-overlay-navigation-item-liveticker"
                        href={getLivetickerHref()}
                        onPress={trackClick('liveticker')}
                        name="Liveticker"
                        imageIcon="live-active"
                        iconSize={14}
                        webRel={nofollowExternal}
                    />
                ) : null}
                {match.competition?.hasStandings ? (
                    <WrappedNavigationListItem
                        {...itemProps}
                        testID="match-overlay-navigation-item-standings"
                        href={createStandingsHref({ tag, competitionId: match.competition?.id })}
                        onPress={trackClick('standings')}
                        name="Tabelle"
                        imageIcon="standings"
                        webRel={nofollowExternal}
                    />
                ) : null}
                {isEventBasedSportEvent(sportIdentifier) ? (
                    <WrappedNavigationListItem
                        {...itemProps}
                        testID="match-overlay-navigation-item-calendar"
                        href={createCalendarHref({ tag, sportId: match.sport?.id })}
                        onPress={trackClick('calendar')}
                        name="Kalender"
                        imageIcon="calendar"
                        webRel={nofollowExternal}
                    />
                ) : (
                    <WrappedNavigationListItem
                        {...itemProps}
                        testID="match-overlay-navigation-item-gameplan"
                        href={createGameplanHref({ tag, competitionId: match.competition?.id })}
                        onPress={trackClick('spielplan')}
                        name="Spielpan"
                        imageIcon="calendar"
                        webRel={nofollowExternal}
                    />
                )}
                {bettingGameURL ? (
                    <WrappedNavigationListItem
                        {...itemProps}
                        testID="match-overlay-navigation-item-betting-game"
                        href={bettingGameURL}
                        onPress={trackClick('tippspiel')}
                        name="Tippspiel"
                        imageIcon="betting-game"
                        webRel={nofollowExternal}
                    />
                ) : null}
                {matchStatus === MatchSheetViewType.PRE_MATCH ? (
                    <WrappedNavigationListItem
                        href=""
                        onPress={downloadCalendarFile}
                        name="Zum Kalender hinzufügen"
                        imageIcon="add-to-calendar"
                        iconSize={18}
                        imageType="icon"
                        rightIcon="none"
                        webRel={nofollowExternal}
                    />
                ) : null}
            </NonFlexingContainer>
            <NonFlexingContainer
                width={12}
                height={12}
                position="absolute"
                backgroundColor="pearl"
                top={top}
                bottom={bottom}
                right={-6}
                rotate={-45}
                zIndex={401}
            />
        </NonFlexingContainer>
    )
}

export default memo(SportEventOverlay)
