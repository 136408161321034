import { PartnerButtonModel } from '@sport1/types/web'

type Props = {
    competitionId?: string
    sportId?: string
} & Pick<PartnerButtonModel['config'], 'showForSports' | 'showForCompetitions'>

const shouldShowPartnerButton = ({
    sportId,
    showForSports,
    competitionId,
    showForCompetitions,
}: Props): boolean => {
    return (
        !!(sportId && showForSports?.length && showForSports.includes(sportId)) ||
        !!(
            competitionId &&
            showForCompetitions?.length &&
            showForCompetitions.includes(competitionId)
        )
    )
}

export default shouldShowPartnerButton
