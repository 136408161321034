import { FC } from 'react'
import SetCard from '@sport1/news-styleguide/SetCard'
import { HeadlineProps } from '@sport1/news-styleguide/MatchCard'
import { SportEventProps } from '../../SportEvent'
import getSetEventProps from '@/components/SportEvent/Set/util/getSetEventProps'
import { getRelatedMatchLabel } from '@/utils/match/getRelatedMatchLabel'
import { getPlayoffLabel } from '@/utils/match/getPlayoffLabel'

type SetProps = Pick<
    SportEventProps,
    'match' | 'headline' | 'spaceTop' | 'spaceY' | 'spaceBottom' | 'trackCardClick' | 'testID'
> & {
    onPressMore?: () => void
    matchHref?: string
}

const Set: FC<SetProps> = ({
    match,
    headline,
    spaceY,
    spaceTop,
    spaceBottom,
    testID,
    onPressMore,
    matchHref,
    trackCardClick,
}) => {
    const setEventProps = getSetEventProps({ event: match })
    const headlineProps = headline
        ? ({
              ...headline,
              href: matchHref,
          } as HeadlineProps)
        : undefined

    return setEventProps ? (
        <SetCard
            testID={testID}
            headlineProps={headlineProps}
            spaceTop={spaceTop}
            spaceBottom={spaceBottom}
            spaceY={spaceY}
            onPressMore={onPressMore}
            setProps={{
                ...setEventProps,
                onPressMatch: matchHref ? trackCardClick : undefined,
                additionalField:
                    ('additionalField' in setEventProps && setEventProps.additionalField) ||
                    getRelatedMatchLabel(match) ||
                    getPlayoffLabel(match),
                href: matchHref,
                paddingTop: 'spacing-none',
            }}
        />
    ) : null
}

export default Set
