import React, { VFC } from 'react'
import {
    LabelProps,
    ResultTeaserCompetitionProps,
    TagProps,
    TrackingDataProps,
} from '@sport1/types/web'
import Button from '@sport1/news-styleguide/Button'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { PercentageType, PixelType, ResponsiveType } from '@sport1/news-styleguide/Types'
import getActionButtons from './util/getActionButtons'
import { createGameplanHref } from '@/utils/navigation/Navigator'
import nofollowExternal from '@/utils/url/nofollowExternal'
import isKnockoutStage from '@/utils/match/isKnockoutStage'
import ActionButtons from '@/components/ActionButtons'
import getSportIdentifier from '@/utils/match/getSportIdentifier'
import { UseResultTeaserTrackingReturnProps } from '@/components/ResultTeaserGeneric/utils/useResultTeaserTracking'
import { ResultTeaserCompetitionCardProps } from '@/components/ResultTeaserGeneric/CompetitionCard'

type Props = Pick<ResultTeaserCompetitionProps, 'matches' | 'maxContentCount'> &
    Pick<UseResultTeaserTrackingReturnProps, 'trackMatchCardClick'> &
    Pick<ResultTeaserCompetitionCardProps, 'positionInSlider' | 'variant'> & {
        width: ResponsiveType<number | PixelType | PercentageType>
        tag?: TagProps | LabelProps
        competitionCardTrackingData?: TrackingDataProps[]
    }

const CompetitionCardActionButtons: VFC<Props> = ({
    matches,
    maxContentCount = 3,
    width,
    tag,
    trackMatchCardClick,
    positionInSlider = 0,
    variant,
    competitionCardTrackingData,
}) => {
    const sportIdentifier = matches[0] ? getSportIdentifier(matches[0]) : undefined
    const sportId = matches[0]?.sport?.id
    const hasStandings = matches.some(
        match => match.competition?.hasStandings && !isKnockoutStage(match?.roundType)
    )

    const onPress = React.useCallback(() => {
        if (matches.length && matches[0]) {
            trackMatchCardClick?.({
                competition: matches[0].competition,
                positionInSlider,
                type: variant,
                competitionCardTrackingData,
            })
        }
    }, [competitionCardTrackingData, matches, positionInSlider, trackMatchCardClick, variant])

    return (
        <NonFlexingContainer marginX="auto" width={width} marginTop="spacing-3">
            {maxContentCount > matches.length ? (
                <Button
                    buttonVariant="highlight"
                    textAlign="center"
                    text="MEHR ANZEIGEN"
                    testID={`button_${tag?.contextId}`}
                    href={createGameplanHref({
                        tag,
                        competitionId: matches[0]?.competition?.id,
                    })}
                    onPress={onPress}
                    expand
                    webRel={nofollowExternal}
                />
            ) : (
                <ActionButtons
                    spaceBetween={16}
                    actionButtons={getActionButtons({
                        hasStandings,
                        matches,
                        sportIdentifier,
                        sportId,
                        tag,
                    })}
                    onPressCallback={onPress}
                />
            )}
        </NonFlexingContainer>
    )
}

export default CompetitionCardActionButtons
