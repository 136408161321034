import { MatchClientModel } from '@sport1/types/sportData'
import getPreEventProps from './getPreEventProps'
import getLiveEventProps from './getLiveEventProps'
import getPostEventProps from './getPostEventProps'
import getSportEventStatus from '@/utils/match/getSportEventStatus'
import getSpecifiedEventProps from '@/components/SportEvent/Event/util/getSpecifiedEventProps'
import { MatchUsageSource } from '@/components/SportEvent/utils'

type EventProps = ReturnType<
    | typeof getPreEventProps
    | typeof getLiveEventProps
    | typeof getPostEventProps
    | typeof getSpecifiedEventProps
>

const getEventProps = ({
    match,
    eventHref,
    onPressMore,
    onPressEvent,
    source = 'default',
}: {
    match: MatchClientModel
    eventHref?: string
    onPressMore?: () => void
    onPressEvent?: () => void
    source?: MatchUsageSource
}): EventProps => {
    const period = getSportEventStatus(match)
    if (period === 'PRE') {
        return getPreEventProps({ match, eventHref, onPressMore, source })
    } else if (period === 'LIVE') {
        return getLiveEventProps({ match, eventHref, onPressMore, source })
    } else if (period === 'POST') {
        return getPostEventProps(match, eventHref, onPressEvent)
    } else if (period === 'SPECIFIED') {
        return getSpecifiedEventProps({ match, eventHref, onPressMore, source })
    }
}

export default getEventProps
